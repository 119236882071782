export const priceTableDataIndex = {
    uid: "uid",
    email: "email",
    role: "role",
    name:  "name",
    phone_number:  "phone_number",
    expiry_date:  "expiry_date",
    active_agreements:  "active_agreements",
    total_agreements:  "total_agreements",
    action: "action",
    expand: "expand",
  };


  export const planTableDateIndex = {
    frequency: "frequency",
    duration: "duration",
    price: "price",
    name:  "name",
    action: "action",
  };


  export const pdfListing = {
    frequency: "frequency",
    duration: "duration",
    price: "price",
    name:  "name",
    action: "action",
  };

  export const documentDataIndex = {
    name: "name",
    uid: "uid",
    key: "key",
    action: "action",
  };