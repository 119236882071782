import React from "react";
import "./ContactCard.css";

const ContactCard = (props) => {
    const { agent = {} } = props;
    const { record = agent } = agent;

    const profilePhoto = record.profile_photo || "https://images.unsplash.com/photo-1511367461989-f85a21fda167?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDEwfHxpbmNpZGVudCUyMGljb258ZW58MHx8fHwxNjAwNzU5NDgz&ixlib=rb-1.2.1&q=80&w=400";
    const phoneNumber = record.phone_number || "Not available";
    const email = record.email || "Not available";
    const brokerName = record.broker_name || "Not available";
    const role = record.role || "Not available";
    const license = record.license || "Not available";

    return (
        <div className="contact-card">
            <div className="contact-header">
                <img
                    src={profilePhoto} // Use the default or provided image
                    alt="Profile"
                    className="profile-image"
                />
                <div className="contact-details">
                    <div className="contact-item">
                        <span className="contact-label">Phone :</span>
                        <span className="contact-value">{phoneNumber}</span>
                    </div>
                    <div className="contact-item">
                        <span className="contact-label">Email :</span>
                        <span className="contact-value">{email}</span>
                    </div>
                    <div className="contact-item">
                        <span className="contact-label">Broker :</span>
                        <span className="contact-value">{brokerName}</span>
                    </div>

                    {record.role === "agent" ? (
                        <div className="contact-item">
                            <span className="contact-label">License :</span>
                            <span className="contact-value">{license}</span>
                        </div>
                    ) : (
                        <div className="contact-item">
                            <span className="contact-label">Status :</span>
                            <span className="contact-value">{role}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

ContactCard.defaultProps = {
    agent: {
        record: {
            profile_photo: "default-profile-photo.png",
            phone_number: "Not available",
            email: "Not available",
            broker_name: "Not available",
            role: "Not available",
            license: "Not available"
        }
    }
};

export default ContactCard;
