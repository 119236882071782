import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table } from "antd";
import { priceTableDataIndex } from "../../staticObjects";
import {
    getAdminClientList
} from "../../../redux/services";
import style from "../../Pages/Dashboard/style.module.scss";
import Admin from "../Admin.scss"
import SearchBar from "../../Common/SearchBar/SearchBar";
import {EyeOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router";


function AdminAgent() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { agentClient } = useSelector((state) => state.client);
    const { data } = useSelector((state) => state?.user);
    const [forRole, setForRole] = useState('broker');


    useEffect(() => {
        const currentUrl = window.location.href;

        if (data.role === "admin") {
            let role = 'broker'; // Default value
            if (data.role === "admin") {
                if (currentUrl.includes('broker')) {
                    role = 'broker';
                } else if (currentUrl.includes('agents')) {
                    role = 'agent';
                } else if (currentUrl.includes('contact')) {
                    role = 'client';
                }
            }
            setForRole(role);

            dispatch(getAdminClientList({
                role: role
            }))
        }
    }, [window.location.href]);

    const { phone_number, email, total_agreements, active_agreements, role, name, expiry_date } = priceTableDataIndex;
    const priceTbCol = [
        { dataIndex: 'name', title: "Name" },
        { dataIndex: 'phone_number', title: "Phone Number" },
        { dataIndex: 'email', title: "Email" },
        { dataIndex: 'role', title: "Status" },
        // { dataIndex: 'total_agreements', title: "Total Agreements" },
        // { dataIndex: 'active_agreements', title: "Active Agreements" },
    ];

    if (window.location.href.includes('contact')) {
        priceTbCol.push({ dataIndex: 'expiry_date', title: "Expiration Date" });
    }

    if (!window.location.href.includes('contact')) {
        priceTbCol.push({ dataIndex: 'action', title: "Action" });
    }

    const handleSearch = (searchTerm) => {
        dispatch(getAdminClientList({
            role: forRole,
            search: searchTerm
        }))
    };

    const handleTotalAgents = (agentdata) => {
        navigate(`/inkadmin/agents/${agentdata.id}`)
    };

    const handleTotalContacts = (agentdata) => {
        navigate(`/inkadmin/contacts/${agentdata.id}`)
    };

    const dataList = (clientdata) => {
        if (clientdata && Array.isArray(clientdata) && clientdata.length > 0) {
            return clientdata?.map((item) => ({
                ...item,
                name: (
                    <>
                    {item.name == null ? 'Unprovided' : item.name}
                    </>
                ),
                phone_number: (
                    <a
                        href={`sms:${item.phone_number}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.phone_number == null ? 'Unprovided' : item.phone_number}
                    </a>
                ),
                email: (
                    <a
                        href={`mailto:${item.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.email}
                    </a>
                ),
                role: (
                    <Button
                        style={{
                            backgroundColor: item.role === 'contact' ? '#B9A143' : '#4CAF50',
                            color: "white", // White text
                            padding: "7px 20px", // Adjusted padding to make the button a bit smaller
                            border: "none", // No border
                            borderRadius: "28px", // Rounded corners
                            cursor: "pointer", // Pointer on hover
                            margin: "5px", // Adjust margin if needed
                            width: "110px", // Set a fixed width
                            display: "flex", // Use flexbox for centering the text
                            justifyContent: "center", // Horizontally center text
                            alignItems: "center", // Vertically center text
                            textAlign: "center" // Ensure text is centered in case of longer text
                        }}
                    >
                        {item.role}
                    </Button>
                ),
                action: (

                    <div className={`${style.buyData}`}>
                        {window.location.href.includes('broker') && (
                            <>
                                <Button
                                    style={{
                                        margin: "2px",
                                        padding: "5px 15px",
                                        backgroundColor: "white",
                                        borderRadius: "20px",
                                    }}
                                    icon={<EyeOutlined />}
                                    onClick={() => handleTotalAgents(item)}
                                >
                                    Total Agents
                                </Button>
                                <Button
                                    style={{
                                        margin: "2px",
                                        padding: "5px 15px",
                                        backgroundColor: "white",
                                        borderRadius: "20px",
                                    }}
                                    icon={<EyeOutlined />}
                                    onClick={() => handleTotalContacts(item)}
                                >
                                    Total Contacts
                                </Button>
                            </>
                        )}
                        {window.location.href.includes('agents') && (
                            <Button
                                style={{
                                    margin: "2px",
                                    padding: "5px 15px",
                                    backgroundColor: "white",
                                    borderRadius: "20px",
                                }}
                                icon={<EyeOutlined />}
                                onClick={() => handleTotalContacts(item)}
                            >
                                Total Contacts
                            </Button>
                        )}
                    </div>
                )
            }));
        } else {
            return [];
        }
    };

    return (
        <>
            <div className="assetTable AdminOuter" style={{ marginTop: '30px' }}>
                <div className={`${style.home}`}>
                    <div className={`${style.home__inner}`} style={{ maxWidth: '100% !important' }}>
                        {data.role === "admin" ? (
                            <div className="pageMain">
                                <div className={style.pricesData}>
                                    <div className={`${style.home__prices}`}>
                                        <div className="page_title d-flex">
                                            <h4>All {`${forRole.charAt(0).toUpperCase() + forRole.slice(1)}s`}</h4>
                                                <div style={{ alignItems: 'end', gap: '10px', marginBottom: '10px' }}>
                                                    <SearchBar
                                                        onSearch={handleSearch}
                                                        placeholder={`Search ${forRole.charAt(0).toUpperCase() + forRole.slice(1)} Name...`}
                                                    />
                                                </div>
                                        </div>
                                        <div className="tableMain">
                                            <Table
                                                rowKey={(obj) => obj.keyId}
                                                columns={priceTbCol}
                                                dataSource={dataList(agentClient)}
                                                pagination={true}
                                                className="priceTable"
                                                style={{ boxShadow: "none" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            " "
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminAgent;
