import React, {useState} from "react";
import {Select, Radio, Form, Input, Button, Space} from "antd";
import {useSelector} from "react-redux";
import PhoneNumberInput from "../../Common/PhoneNumberComponenet/PhoneNumberInput";
import { DatePicker } from 'antd';
import moment from 'moment';
import { Row, Col, Checkbox } from 'antd';

const SendPdfForm = ({ onSubmit, selectedPDF }) => {
    const { data } = useSelector((state) => state?.user);
    const CheckboxGroup = Checkbox.Group;
    const [fontFamily, setFontFamily] = useState("");

    const changeFont = (font) => {
        setFontFamily(font);
    };

    const optionsLeft = [
        { label: "Information About Brokerage Services", value: "brokerageServices" },
        { label: "Mold Remediation Consumer Protection", value: "moldRemediation" },
        { label: "Information Concerning Property Insurance", value: "propertyInsurance" },
        { label: "General Information and Notice to Buyers and Sellers", value: "generalInfo" },
    ];

    const optionsRight = [
        { label: "Protect Your Family from Lead in Your Home", value: "leadProtection" },
        { label: "Information about Special Flood Hazard Areas", value: "floodHazard" },
        { label: "For Your Protection: Get a Home Inspection", value: "homeInspection" },
        { label: "Wire Fraud Warning", value: "wireFraud" },
    ];

    return (
    <Form
      layout={"vertical"}
      onFinish={(values) => {
        if (onSubmit) {
          onSubmit(values); // Call parent component's handler if provided
        }
      }}
      initialValues={selectedPDF}
    >
     {selectedPDF?.set_title &&  (<Form.Item
                    label="Template title"
                    name="title"
                    rules={[{ required: true, message: "Please enter the Template title" }]}
                >
                    <Input placeholder="Enter the title" />
                </Form.Item>)}

      {/* Dynamically render additional fields based on selected PDF */}
        {selectedPDF?.template_name.includes("residential_buyer_tenant_agreement") && (
            <>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                <Form.Item
                    label="Property Address"
                    name="property_address"
                    rules={[{ required: true, message: "Please enter the address" }]}
                >
                    <Input placeholder="Enter the address" />
                </Form.Item>

                <Form.Item
                    label="City"
                    name="city"
                    rules={[{ required: true, message: "Please enter the city" }]}
                >
                    <Input placeholder="Enter the city" />
                </Form.Item>

                <Form.Item
                    label="County"
                    name="county"
                    rules={[{ required: true, message: "Please enter the county" }]}
                >
                    <Input placeholder="Enter the county" />
                </Form.Item>

                <Form.Item
                    label="State"
                    name="subdivision"
                    rules={[{ required: true, message: "Please enter the state" }]}
                >
                    <Input placeholder="Enter the state" />
                </Form.Item>

                <Form.Item
                    label="Zip Code"
                    name="zip_code"
                    rules={[{ required: true, message: "Please enter the zip code" }]}
                >
                    <Input placeholder="Enter the zip code" />
                </Form.Item>

                <Form.Item
                    label="Agreement Begins on"
                    name="start_date"
                    rules={[{ required: true, message: "Please enter the start date" }]}
                >
                    <DatePicker
                        format="YYYY-MM-DD"
                        placeholder="Enter the start date"
                        style={{ width: '100%' }} // Optional: to make it responsive
                    />
                </Form.Item>

                <Form.Item
                    label="Agreement Ends on"
                    name="end_date"
                    rules={[
                        { required: true, message: "Please enter the end date" },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || !getFieldValue('start_date') || value.isAfter(getFieldValue('start_date'))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('End date must be after the start date!'));
                            },
                        }),
                    ]}
                >
                    <DatePicker
                        format="YYYY-MM-DD"
                        placeholder="Enter the end date"
                        style={{ width: '100%' }} // Optional: to make it responsive
                    />
                </Form.Item>
                <Form.Item required name="construction_fee" label="Construction Fee" style={{ marginBottom: '8px' }}>
                    <Input placeholder="Construction Fee Amount" />
                </Form.Item>

                <Form.Item required name="referral_fee" label="Service Provider Referral Fee" style={{ marginBottom: '8px' }}>
                    <Input placeholder="Enter referral fees" />
                </Form.Item>

                <Form.Item
                    label="Amounts Payable to Broker"
                    style={{ marginBottom: '8px' }}
                    required
                >
                    <Input placeholder="Amount payable in County, Texas" />
                </Form.Item>

                <Form.Item
                    label="Entity Providing Benefits"
                    name="benefit_provider"
                    rules={[{ required: true, message: "Please enter the name of the employer or entity" }]}
                >
                    <Input placeholder="Enter name of employer or relocation company" />
                </Form.Item>
                <Form.Item
                    label="Protection Period (in days)"
                    name="protection_period"
                    rules={[{ required: true, message: "Please enter the protection period" }]}
                >
                    <Input type="number" placeholder="Enter protection period in days" />
                </Form.Item>

                <Form.Item
                    label="Broker Payment County"
                    name="broker_payment_county"
                    rules={[{ required: true, message: "Please enter the county" }]}
                >
                    <Input placeholder="Enter the county" />
                </Form.Item>
            </div>
<div>
                <div style={{maxWidth: '600px'}}>
                    <h2>Broker's Fees</h2>
                    <p>When Earned and Payable, Client will pay Broker (Complete all that apply):</p>
                    <Row gutter={16}>
                        {/* Purchases */}
                        <Col span={12}>
                            <Form.Item
                                name="purchasePercentage"
                                label="(Purchases) % of the sales price"
                            >
                                <Input placeholder="%"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="purchaseFixed" label="or $">
                                <Input placeholder="$"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                    <Row gutter={16}>
                        {/* Leases */}
                        <Col span={12}>
                            <Form.Item
                                name="leasePercentage"
                                label="(Leases) % of one month's rent"
                            >
                                <Input placeholder="%"/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="leaseFixed" label="or $">
                                <Input placeholder="$"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="leaseAllRentsPercentage"
                                label="or % of all rents over term of lease"
                            >
                                <Input placeholder="%"/>
                            </Form.Item>
                        </Col>
                    </Row>
                <Form.Item
                    label="INTERMEDIARY"
                    name="intermediary_status"
                    rules={[{ required: true, message: "Please select an intermediary status" }]}
                >
                    <Radio.Group>
                        <Radio value="A" style={{ marginRight: '20px' }}>
                            A. Intermediary Status: Client desires to see Broker's listings. If Client wishes to acquire one of Broker's listings, Client authorizes Broker to act as an intermediary and Broker will notify Client that Broker will service the parties in accordance with one of the following alternatives. (Summarize alternatives if needed)
                        </Radio>
                        <Radio value="B">
                            B. No Intermediary Status: Client does not wish to be shown or acquire any of Broker's listings.
                        </Radio>
                    </Radio.Group>
                </Form.Item>
    <Form.Item label="Addenda" style={{ marginBottom: 0 }}>
        <Row>
            <Col span={12}>
                {optionsLeft.map((option) => (
                    <Form.Item key={option.value} name={option.value} valuePropName="checked" initialValue={false}>
                        <Checkbox>{option.label}</Checkbox>
                    </Form.Item>
                ))}
            </Col>
            <Col span={12}>
                {optionsRight.map((option) => (
                    <Form.Item key={option.value} name={option.value} valuePropName="checked" initialValue={false}>
                        <Checkbox>{option.label}</Checkbox>
                    </Form.Item>
                ))}
            </Col>
        </Row>
    </Form.Item>
    <Form.Item
        label="Signature"
        name="initials"
        rules={[{ required: true, message: "Please input a description!" }]}
    >
        <Input.TextArea
            placeholder="Enter your signature"
            class={ fontFamily }
        />
    </Form.Item>
    <Space  >
        <Button
            onClick={() => changeFont("great-vibes")}
            style={{ marginRight: "10px" }}
        >
            <span className={ "great-vibes"}> T</span>
        </Button>
        <Button
            onClick={() => changeFont("dancing-script")}
            style={{ marginRight: "10px" }}
        >
            <span className={"dancing-script"}> T</span>
        </Button>
        <Button
            onClick={() => changeFont("pacifico")}
            style={{ marginRight: "10px" }}
        >
            <span className={"pacifico"}> T</span>
        </Button>
        <Button
            onClick={() => changeFont("satisfy ")}
            style={{ marginRight: "10px" }}
        >
            <span className={"satisfy"}> T</span>
        </Button>
    </Space>
</div>
            </>
        )}



      {selectedPDF?.template_name?.includes("residential_buyer_tenant_short_agreement") && (
          <>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
              <Form.Item
                  label="Property Address"
                  name="property_address"
                  rules={[{ required: true, message: "Please enter the address" }]}
              >
                  <Input placeholder="Enter the address" />
              </Form.Item>

              <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: true, message: "Please enter the city" }]}
              >
                  <Input placeholder="Enter the city" />
              </Form.Item>

              <Form.Item
                  label="County"
                  name="county"
                  rules={[{ required: true, message: "Please enter the county" }]}
              >
                  <Input placeholder="Enter the county" />
              </Form.Item>

              <Form.Item
                  label="State"
                  name="subdivision"
                  rules={[{ required: true, message: "Please enter the state" }]}
              >
                  <Input placeholder="Enter the state" />
              </Form.Item>
              <Form.Item
                  label="Zip Code"
                  name="zip_code"
                  rules={[{ required: true, message: "Please enter the zip code" }]}
              >
                  <Input placeholder="Enter the zip code" />
              </Form.Item>

              <Form.Item
                  label="Agreement Begins on"
                  name="start_date"
                  rules={[{ required: true, message: "Please enter the start date" }]}
              >
                  <DatePicker
                      format="YYYY-MM-DD"
                      placeholder="Enter the start date"
                      style={{ width: '100%' }} // Optional: to make it responsive
                  />
              </Form.Item>

              <Form.Item
                  label="Agreement Ends on"
                  name="end_date"
                  rules={[
                      { required: true, message: "Please enter the end date" },
                      ({ getFieldValue }) => ({
                          validator(_, value) {
                              if (!value || !getFieldValue('start_date') || value.isAfter(getFieldValue('start_date'))) {
                                  return Promise.resolve();
                              }
                              return Promise.reject(new Error('End date must be after the start date!'));
                          },
                      }),
                  ]}
              >
                  <DatePicker
                      format="YYYY-MM-DD"
                      placeholder="Enter the end date"
                      style={{ width: '100%' }} // Optional: to make it responsive
                  />
              </Form.Item>
              <Form.Item label="Broker's Fee (When Earned and Payable)" style={{ marginBottom: '8px' }} required>
                  <Input.Group compact>
                      <Form.Item name="broker_fee_amount" noStyle>
                          <Input style={{ width: '50%' }} placeholder="Enter fee amount" />
                      </Form.Item>
                      <   Form.Item name="broker_fee_currency" noStyle>
                          <Select defaultValue="$" style={{ width: '50%' }}>
                              <Select.Option value="$">$</Select.Option>
                              <Select.Option value="%">%</Select.Option>
                          </Select>
                      </Form.Item>
                  </Input.Group>
              </Form.Item>
          </div>
              <div style={{ maxWidth: '600px', margin: '0 auto' }}>
                  <h2>Broker's Fees</h2>
                  <p>When Earned and Payable, Client will pay Broker (Complete all that apply):</p>
                   <Row gutter={16}>
                          {/* Purchases */}
                          <Col span={12}>
                              <Form.Item
                                  name="purchasePercentage"
                                  label="(Purchases) % of the sales price"
                              >
                                  <Input placeholder="%" />
                              </Form.Item>
                          </Col>
                          <Col span={12}>
                              <Form.Item name="purchaseFixed" label="or $">
                                  <Input placeholder="$" />
                              </Form.Item>
                          </Col>
                      </Row>

                      <Row gutter={16}>
                          {/* Leases */}
                          <Col span={12}>
                              <Form.Item
                                  name="leasePercentage"
                                  label="(Leases) % of one month's rent"
                              >
                                  <Input placeholder="%" />
                              </Form.Item>
                          </Col>
                          <Col span={12}>
                              <Form.Item
                                  name="leaseAllRentsPercentage"
                                  label="or % of all rents over term of lease"
                              >
                                  <Input placeholder="%" />
                              </Form.Item>
                          </Col>
                      </Row>

                      <Row gutter={16}>
                          <Col span={12}>
                              <Form.Item name="leaseFixed" label="or $">
                                  <Input placeholder="$" />
                              </Form.Item>
                          </Col>
                      </Row>

                  <Form.Item label="BROKER OBLIGATIONS">
                      <p>
                          Broker must comply with minimum duties as required by law. Broker may represent other
                          prospective buyers or tenants who may seek to acquire the same properties as Client.
                      </p>

                      <Form.Item name="full_services" valuePropName="checked" initialValue={false} >
                          <Checkbox>
                              Full Services. Broker will use Broker's best efforts to assist Client in the acquisition of
                              property in the market area.
                          </Checkbox>
                      </Form.Item>

                      <Form.Item name="showing_services" valuePropName="checked" initialValue={false}>
                          <Checkbox>
                              Showing Services. Paragraphs 6, 7, and 8 do not apply. Broker will provide Client with access
                              to properties in the market area.
                          </Checkbox>
                      </Form.Item>
                  </Form.Item>
                      <Form.Item
                          label="Signature"
                          name="initials"
                          rules={[{required: true, message: "Please input a description!"}]}
                      >
                          <Input.TextArea
                              placeholder="Enter your signature"
                              class={fontFamily}
                          />
                      </Form.Item>
                      <Space>
                          <Button
                              onClick={() => changeFont("great-vibes")}
                              style={{marginRight: "10px"}}
                          >
                              <span class={"great-vibes"}> T</span>
                          </Button>
                          <Button
                              onClick={() => changeFont("dancing-script")}
                              style={{marginRight: "10px"}}
                          >
                              <span className={"dancing-script"}> T</span>
                          </Button>
                          <Button
                              onClick={() => changeFont("pacifico")}
                              style={{marginRight: "10px"}}
                          >
                              <span className={"pacifico"}> T</span>
                          </Button>
                          <Button
                              onClick={() => changeFont("satisfy ")}
                              style={{marginRight: "10px"}}
                          >
                              <span className={"satisfy"}> T</span>
                          </Button>
                      </Space>

                  <Form.Item label="Intermediary Authorization" name='intermediary' valuePropName="checked" initialValue={false}>
                      <Checkbox>
                          I authorize the Broker to act as an intermediary for acquiring listings.
                      </Checkbox>
                  </Form.Item>

              </div>
          </>
      )}

      {selectedPDF?.template_name?.includes("information_brokerage_agreement") && (
        <>
          {/* <Form.Item
            label="Broker Firm Name"
            name="broker_firm_name"
            rules={[{ required: true, message: "Please enter your Firm name" }]} // Required validation rule
          >
            <Input placeholder="Enter your Firm Name" />
          </Form.Item>

          <Form.Item
            label="Broker License Number"
            name="broker_license_number"
            rules={[{ required: true, message: "Please enter your License number" }]} // Required validation rule
          >
            <Input placeholder="Enter your License Number" />
          </Form.Item>

          <Form.Item
            label="Broker Email"
            name="broker_email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]} // Optional field with email validation
          >
            <Input placeholder="Enter your email address" />
          </Form.Item>

          <Form.Item
            label="Broker Phone Number"
            name="broker_phone_number"
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]} // Optional field with phone validation
          >
            <Input placeholder="Enter your phone number" />
          </Form.Item> */}


          {data.role === "broker"
               ?
              (<><Form.Item
            label="Sales Agent"
            name="agent_name"
            rules={[{ required: true, message: "Please enter the agent's name" }]} // Required validation rule
          >
            <Input placeholder="Enter the agent's name" />
          </Form.Item>

          <Form.Item
            label="Sales Agent License"
            name="agent_license_number"
            rules={[{ required: true, message: "Please enter the agent's license number" }]} // Required validation rule
          >
            <Input placeholder="Enter the agent's license number" />
          </Form.Item>

          <Form.Item
            label="Sales Agent Email"
            name="agent_email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter a valid email address",
              },
            ]} // Optional field with email validation
          >
            <Input placeholder="Enter the agent's email address" />
          </Form.Item>

          <Form.Item
            label="Sales Agent Phone"
            name="agent_phone_number"
            rules={[
              {
                required: true,
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]} // Optional field with phone validation
          >
            <Input placeholder="Enter the agent's phone number" />
          </Form.Item>
            </>)

        :
          (<>
          {/* (<Form.Item
            label="Broker Firm Name"
            name="broker_firm_name"
            rules={[{ required: true, message: "Please enter your Firm name" }]} // Required validation rule
          >
            <Input placeholder="Enter your Firm Name" />
          </Form.Item> ) */}



          <Form.Item
            label="Broker Name"
            name="broker_name"
            rules={[{ required: true, message: "Please enter your Broker name" }]} // Required validation rule
          >
            <Input placeholder="Enter Broker Name" />
          </Form.Item>


          <Form.Item
            label="Broker License Number"
            name="broker_license_number"
            rules={[{ required: true, message: "Please enter your License number" }]} // Required validation rule
          >
            <Input placeholder="Enter your License Number" />
          </Form.Item>

          <Form.Item
            label="Broker Email"
            name="broker_email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]} // Optional field with email validation
          >
            <Input placeholder="Enter your email address" />
          </Form.Item>

          <Form.Item
            label="Broker Phone Number"
            name="broker_phone_number"
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]} // Optional field with phone validation
          >
            <Input placeholder="Enter your phone number" />
          </Form.Item>
              </>)
          }
        </>
      )}

      {/* Submit Button */}
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
};

export default SendPdfForm;
