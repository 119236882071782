import React, { useState, useEffect } from "react";
import {Form, Input, Button, Select} from "antd";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";

const ClientForm = ({ client,extraData, onSave, onCancel }) => {
  const [form] = Form.useForm();
  const [showExtraFields, setShowExtraFields] = useState(false); // Control extra fields
  const [showSpouseName , setShowSpouseName] = useState(false);
  const [value , setValue] = useState('');

  useEffect(() => {
    if (client) {
      setShowExtraFields(true);
        const updatedClient = {
            ...client,
            name: client?.name == null ? '' : client?.name,
            spouse_name: client?.spouse_name == null ? '' : client?.spouse_name,
        };

        form.setFieldsValue(updatedClient);
    } else {
      if (extraData){
        setShowSpouseName(true);
      }
      setShowExtraFields(false);
      form.resetFields();
    }
  }, [client, form]);

  const handleSubmit = (values) => {
      const filteredValues = Object.fromEntries(
          Object.entries(values).filter(([key, value]) => value !== undefined)
      );
      onSave(filteredValues);
  };

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      {showExtraFields && (
        <>
          <Form.Item name="id" label="ID" style={{ display: 'none' }}>
            <Input disabled={true} />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="name"
        label="Name"
        rules={
                (client?.role === 'agent' || showSpouseName === false) &&
                (client?.role !== 'contact' && client?.role !== 'client')
                    ? [{ required: true, message: "Please enter the name" }]
                    : []
            }
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: "Please enter the email" }]}
      >
        <Input disabled={client ? true : false} />
      </Form.Item>

        {(client?.role === 'agent' || showSpouseName === false) && (
            (client?.role !== 'contact' &&   client?.role !== 'client') ? (
        <Form.Item
            name="license"
            label="License"
            rules={[{ required: true, message: "Please enter the license key" }]}
        >
            <Input />
        </Form.Item>
            ) : null
        )}

      <Form.Item
        label="Phone Number"
        name="phone_number"
        rules={[{ required: true, message: "Please enter the phone number" }]}
      >
          <PhoneInput
              defaultCountry="US"
              placeholder="Enter phone number"
              value={value}
              required
              onChange={setValue}
              error={value ? (isValidPhoneNumber(value) ? undefined : 'Invalid phone number') : 'Phone number required'}/>
      </Form.Item>

        {showSpouseName && (
            <Form.Item name="spouse_name" label="Spouse Name"
                // rules={[{ required: true, message: "Please enter the spouse name" }]}
            >
                <Input />
            </Form.Item>
        )}

      {showExtraFields && (
        <>
          <Form.Item name="role" label="Status">
              <Select>
                  <Select.Option value="client">Client</Select.Option>
                  <Select.Option value="contact">Contact</Select.Option>
              </Select>
          </Form.Item>

        <Form.Item name="expiry_date" label="Client Expiration Date">
            <Input disabled={true} />
        </Form.Item>
            <p>(determined via signed document field)</p>

            {/*<Form.Item name="uid" label="UID">*/}
          {/*  <Input disabled />*/}
          {/*</Form.Item>*/}
        </>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button style={{ marginLeft: "8px" }} onClick={onCancel}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ClientForm;
