import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { rootName } from "../utils/constant";
import {useLocation} from "react-router";

const PublicRoute = ({ isAuthenticated }) => {
  const location = useLocation();

  const excludedPaths = ['/faq', '/terms-conditions'];

  const shouldRedirect = !excludedPaths.includes(location.pathname);

  return isAuthenticated && shouldRedirect ? (
      <Navigate to={`${rootName}`} />
  ) : (
      <Outlet />
  );
};

export default PublicRoute;
