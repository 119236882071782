import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isMobileDevice: window.innerWidth < 768 || window.innerHeight < 500, // Detect initial mobile state
};

const screenSlice = createSlice({
    name: 'screen',
    initialState,
    reducers: {
        setMobileDevice(state, action) {
            state.isMobileDevice = action.payload;
        },
    },
});

export const { setMobileDevice } = screenSlice.actions;
export const selectMobileDeviceState = (state) => state.screen.isMobileDevice;
export default screenSlice.reducer;
