import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import './SearchBar.scss';

const SearchBar = ({ onSearch, placeholder = "Search Clients, Documents" }) => {
    const [query, setQuery] = useState('');

    const handleInputChange = (event) => {
        setQuery(event.target.value);
    };

    const debouncedSearch = debounce((searchTerm) => {
        onSearch(searchTerm);
    }, 300);

    useEffect(() => {
        debouncedSearch(query);
        return () => {
            debouncedSearch.cancel();
        };
    }, [query]);

    return (
        <div className="search-bar">
            <svg
                className="search-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" y1="21" x2="16.65" y2="16.65" />
            </svg>
            <input
                type="text"
                placeholder={placeholder}
                value={query}
                onChange={handleInputChange}
                className="search-input"
            />
        </div>
    );
};

export default SearchBar;
